// extracted by mini-css-extract-plugin
export var alignLeft = "x_q8 d_fp d_bG d_dv";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignRight = "x_q9 d_fr d_bH d_dx";
export var verticalSolidComponent = "x_tn";
export var compContent = "x_th d_r";
export var normal = "x_tp d_Z d_cb";
export var reverse = "x_tq d_Z d_bK";
export var last = "x_tj";
export var empty = "x_tr d_r";
export var empty2 = "x_ts d_bw";
export var borderRight = "x_tt d_b8 d_c1";
export var borderLeft = "x_tv d_b9 d_c1";