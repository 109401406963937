// extracted by mini-css-extract-plugin
export var alignLeft = "w_q8 d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_q9 d_fr d_bH d_dx";
export var verticalDottedFirstComponent = "w_td";
export var wrap = "w_tf d_bJ d_cb";
export var topPadding = "w_tg d_cX";
export var compContent = "w_th d_w";
export var last = "w_tj";
export var box = "w_tk d_cr";
export var boxWrapper = "w_tl d_w d_bz";
export var boxWrapperFirst = "w_tm d_w d_bz";