// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "p_qR d_gv d_cs";
export var heroHeaderCenter = "p_gw d_gw d_cs d_dw";
export var heroHeaderRight = "p_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "p_qS d_gr d_cw";
export var heroParagraphCenter = "p_gs d_gs d_cw d_dw";
export var heroParagraphRight = "p_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "p_qT d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "p_qV d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "p_qW d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "p_qX d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "p_qY d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "p_qZ v_qZ";
export var heroExceptionNormal = "p_q0 v_q0";
export var heroExceptionLarge = "p_q1 v_q1";
export var Title1Small = "p_q2 v_q2 v_r8 v_r9";
export var Title1Normal = "p_q3 v_q3 v_r8 v_sb";
export var Title1Large = "p_q4 v_q4 v_r8 v_sc";
export var BodySmall = "p_q5 v_q5 v_r8 v_ss";
export var BodyNormal = "p_q6 v_q6 v_r8 v_st";
export var BodyLarge = "p_q7 v_q7 v_r8 v_sv";