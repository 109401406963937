// extracted by mini-css-extract-plugin
export var lbContainerOuter = "D_wb";
export var lbContainerInner = "D_wc";
export var movingForwards = "D_wd";
export var movingForwardsOther = "D_wf";
export var movingBackwards = "D_wg";
export var movingBackwardsOther = "D_wh";
export var lbImage = "D_wj";
export var lbOtherImage = "D_wk";
export var prevButton = "D_wl";
export var nextButton = "D_wm";
export var closing = "D_wn";
export var appear = "D_wp";